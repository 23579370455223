.red-row th {
  color: red;
  text-align: left;
}

.orange-row th {
  color: orange;
  text-align: left;
}

.green-row th {
  color: green;
  text-align: left;
}
