.link-parsing-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.link-parsing-form button {
  margin: 10px auto;
  width: fit-content;
}

.link-parsing-form input {
  display: none;
}

.link-parsing-form-row {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.test-links {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.test-links-row a {
  min-width: 5rem;
}

.test-links-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
